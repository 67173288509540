import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import ClockIcon from 'shared/icons/clock-icon'
import LockIcon from 'shared/icons/lock-icon'
import useStepRouter from 'modules/course/hooks/use-step-router'
import type { CourseMenuLectureInterface } from 'modules/course/types/course-menu-interface'
import { addPublicCustomClassNamePrefix } from 'modules/course/utils/add-class-prefix'

interface CourseAvailabilityIconProps {
  isPostponed: boolean
  lectureId?: CourseMenuLectureInterface['id']
  redirect?: boolean
}

export default function CourseAvailabilityIcon({
  isPostponed,
  lectureId,
  redirect,
}: CourseAvailabilityIconProps) {
  const { getLectureUrl } = useStepRouter()
  const LockIconWrapper = redirect && lectureId ? LinkWithoutPrefetch : 'span'

  return isPostponed ? (
    <ClockIcon className="h-[15px] w-[13.3px] fill-THEME-primary" />
  ) : (
    <LockIconWrapper
      className={addPublicCustomClassNamePrefix('course-modules-list__module-sales-page-link')}
      href={getLectureUrl(lectureId!)}
      scroll={false}
    >
      <LockIcon className="h-[17px] w-[13.3px] fill-THEME-primary" />
    </LockIconWrapper>
  )
}
