import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import Head from 'next/dist/shared/lib/head'
import useDomainAssets from 'shared/hooks/use-domain-assets'
import Header from 'modules/header'
import { useCourseData } from '../hooks/use-course-data'
import { useSetFonts } from '../hooks/use-set-fonts'
import { addPublicCustomClassNamePrefix } from '../utils/add-class-prefix'

function CoursePageLayout({ children }: React.PropsWithChildren<{}>) {
  const { t } = useTranslation()
  const { domainAssets } = useDomainAssets()

  const { courseData } = useCourseData()

  const { fontStyle, fontFamily } = useSetFonts()
  return (
    <div
      className={`min-h-full bg-THEME-background ${addPublicCustomClassNamePrefix('course__app')}`}
      style={{
        fontFamily: `${fontFamily ? `${fontFamily}, ` : ''}AvertaPE, sans-serif`,
      }}
    >
      <Head>
        <title>{courseData?.name || t('course.course_title')}</title>
        {domainAssets?.faviconUrl && <link rel="icon" href={domainAssets.faviconUrl} />}
        <style>{fontStyle}</style>
      </Head>
      <Header
        logoUrl={courseData?.logo?.path || domainAssets?.logoUrl}
        className="!bg-THEME-header-background font-[unset!important]"
        mobileSideMenuClassName="!bg-THEME-block-background"
        desktopMenuItemClassName="!text-THEME-header-text hover:!text-THEME-hover-header-text"
        desktopActiveMenuItemClassName="!text-THEME-hover-header-text"
        menuIconClassName="fill-THEME-header-text stroke-THEME-header-text hover:fill-THEME-hover-header-text hover:stroke-THEME-hover-header-text"
        activeMenuIconClassName="fill-THEME-hover-header-text stroke-THEME-hover-header-text"
        avatarLinkClassName="!text-THEME-primary hover:!text-THEME-hover-primary hover:!bg-transparent"
        avatarPopupClassName="!bg-THEME-block-background [&>hr]:text-THEME-disabled"
        mobileMenuItemClassName={`!text-THEME-primary hover:!text-THEME-hover-primary
          [&>svg]:!text-THEME-primary [&:hover>svg]:!text-THEME-hover-primary
        `}
        burgerClassName="[&>path]:stroke-THEME-header-text"
        languageSwitcherClassName={`!text-THEME-primary hover:!text-THEME-hover-primary 
          lg:!text-THEME-header-text lg:hover:!text-THEME-hover-header-text hover:!bg-transparent`}
        listLanguageSwitcherClassName="!bg-THEME-block-background"
        listLanguageSwitcherItemClassName={`!text-THEME-primary 
          hover:!text-THEME-hover-primary hover:!bg-THEME-block-background
        `}
      />
      <div
        className={`mx-auto max-w-[1747px] px-5 py-5 sm:px-6 md:px-5 ${addPublicCustomClassNamePrefix(
          'course__main',
        )}`}
      >
        {children}
      </div>
    </div>
  )
}

export function getCoursePageLayout(page: ReactElement) {
  return <CoursePageLayout>{page}</CoursePageLayout>
}
