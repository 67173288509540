import React from 'react'
import { Trans, useTranslation } from 'next-i18next'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import useUser from 'shared/hooks/use-user'
import { getLoginUrl } from 'shared/utils/get-login-url'
import { PrimaryButtonWithCustomTheme } from 'modules/course/components/primary-button-with-custom-theme'
import { useCourseData } from 'modules/course/hooks/use-course-data'
import { addPublicCustomClassNamePrefix } from 'modules/course/utils/add-class-prefix'
import { CourseInfoScreenLayout } from './course-info-screen-layout'

export const CourseLoginOrPurchaseScreen = () => {
  const { t } = useTranslation()

  const { user } = useUser()
  const { courseData } = useCourseData()

  return (
    <CourseInfoScreenLayout
      className={addPublicCustomClassNamePrefix(`course__info-screen--login-or-purchase`)}
      text={
        <Trans
          i18nKey="course.login_or_purchase_message"
          components={{
            i: <div className="flex justify-center" />,
            a: (
              <LinkWithoutPrefetch
                className="primary-link"
                href={(user?.dashboardLocale && getLoginUrl(user.dashboardLocale)) || ''}
              />
            ),
          }}
        />
      }
      buttons={
        <PrimaryButtonWithCustomTheme
          className={`font-bold ${addPublicCustomClassNamePrefix(
            'course__button--purchase-course',
          )}`}
          href={courseData?.salesPage || ''}
          target="_blank"
        >
          {t('course.purchase_course_button')}
        </PrimaryButtonWithCustomTheme>
      }
    />
  )
}
