import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import CertificateIcon from 'shared/icons/certificate-icon'
import ChevronDownIcon from 'shared/icons/chevron-up-icon'
import CircleIcon from 'shared/icons/circle-icon'
import QuizIcon from 'shared/icons/quiz-icon'
import RoundedCheckIcon from 'shared/icons/rounded-check-icon'
import { classWithModifiers } from 'shared/utils/className-with-modifiers'
import { scrollIntoCenterView } from 'shared/utils/scroll-into-center-view'
import useCertificateOfCompletion from 'modules/course/hooks/use-certificate-of-completion'
import { useCourseData } from 'modules/course/hooks/use-course-data'
import { useCourseMenu } from 'modules/course/hooks/use-course-menu'
import useStepRouter from 'modules/course/hooks/use-step-router'
import { CourseAccessTypeEnum } from 'modules/course/types/course-interface'
import { CourseStepEnum } from 'modules/course/types/course-step-type'
import { addPublicCustomClassNamePrefix } from 'modules/course/utils/add-class-prefix'
import CourseAvailabilityIcon from './availability-icon'

export interface CourseModuleListProps {
  className?: string
}

export const CourseModuleList = React.forwardRef<HTMLUListElement, CourseModuleListProps>(
  function CourseModuleList({}, forwardedRef) {
    const skipScroll = useRef<boolean>(false)
    const ref = useRef<HTMLUListElement>(null)
    const lecturesListRef = useRef<HTMLUListElement>(null)

    const { isCertificateAvailable, certificatePageUrl, isOnCertificatePage } =
      useCertificateOfCompletion()

    useImperativeHandle(forwardedRef, () => ref.current as HTMLUListElement)

    const { isCurrentLecture, isCurrentQuiz, getLectureUrl, getQuizUrl, step } = useStepRouter()

    const selectedStepRef = useRef<HTMLLIElement>(null)

    const { t } = useTranslation()

    const [isModuleOpened, setIsModuleOpened] = useState<Array<boolean>>([])
    const { courseData } = useCourseData()
    const { courseMenu } = useCourseMenu({ courseId: courseData?.id })

    const onToggleModule = (index: number) => {
      setIsModuleOpened(prev => {
        const newState = [...prev]
        newState[index] = !newState[index]
        return newState
      })
    }

    useEffect(() => {
      if (courseMenu) {
        const moduleIndex = courseMenu?.findIndex(
          module =>
            module.lectures.some(({ id }) => id === step?.id) || step?.id === module.quiz?.id,
        )
        if (moduleIndex !== -1) {
          const newState = courseMenu.map(() => false)
          newState[moduleIndex] = true
          setIsModuleOpened(newState)
        }
      }
    }, [courseMenu, step?.id, step?.type])

    useEffect(() => {
      if (!skipScroll.current && courseMenu && ref.current && selectedStepRef.current) {
        scrollIntoCenterView(ref.current, selectedStepRef.current)
      } else {
        skipScroll.current = false
      }
    }, [courseMenu, step?.id, step?.type])

    return (
      <ul
        ref={ref}
        className={twMerge(
          `customScroll mt-9 overflow-y-auto`,
          addPublicCustomClassNamePrefix('course-modules-list'),
        )}
      >
        {courseMenu?.map((module, index) => {
          const {
            available: isModuleAvailable,
            shouldShowQuiz,
            salesPage,
            lectures,
            isCompleted: isModuleCompleted,
            quiz,
          } = module

          const isModuleCurrent = lectures.some(isCurrentLecture)
          const isQuizCurrent = isCurrentQuiz(quiz)
          const canPurchase = !!salesPage

          const drippingAccess =
            courseData?.dominantAccessType === CourseAccessTypeEnum.DrippingContent

          return (
            <li
              key={index}
              className={classWithModifiers(
                addPublicCustomClassNamePrefix('course-modules-list__module-container'),
                {
                  ['opened']: isModuleOpened[index],
                  ['available']: isModuleAvailable,
                  ['completed']: isModuleCompleted,
                },
              )}
            >
              {
                <div
                  className={twMerge(
                    `flex items-center gap-1`,
                    courseData?.displayModuleTitles ? 'mb-1' : 'hidden',
                    addPublicCustomClassNamePrefix('course-modules-list__module-title'),
                  )}
                >
                  <button
                    className={twMerge(
                      `word-break-word mt-0 flex items-center gap-1 break-words text-left text-lg leading-[22px]`,
                      isModuleAvailable
                        ? 'text-THEME-primary hover:text-THEME-hover-primary'
                        : 'text-THEME-disabled',
                      !isModuleCompleted && 'font-bold',
                    )}
                    onClick={() => onToggleModule(index)}
                  >
                    <ChevronDownIcon
                      className={twMerge(
                        `[&>path]:stroke-THEME-primary`,
                        isModuleOpened[index] && 'rotate-180',
                        !isModuleAvailable && '[&>path]:stroke-THEME-disabled',
                      )}
                    />
                    {module.name}
                  </button>
                  {!isModuleAvailable && (
                    <CourseAvailabilityIcon
                      isPostponed={!!drippingAccess}
                      lectureId={module.lectures?.[0]?.id}
                      redirect={canPurchase}
                    />
                  )}
                </div>
              }
              <div
                // NOTE: this is hack for animation
                className={twMerge(
                  `grid transition-[grid-template-rows] duration-200`,
                  isModuleOpened[index] || !courseData?.displayModuleTitles
                    ? 'grid-rows-[1fr]'
                    : 'grid-rows-[0fr]',
                )}
              >
                <ul
                  ref={isModuleCurrent ? lecturesListRef : undefined}
                  className={twMerge(
                    `overflow-hidden`,
                    courseData?.displayModuleTitles && 'mb-8 ml-4',
                  )}
                >
                  {lectures.map(lecture => {
                    const isLectureCurrent =
                      step?.type === CourseStepEnum.lecture && step.id === lecture.id
                    return (
                      <li
                        ref={isLectureCurrent ? selectedStepRef : undefined}
                        key={lecture.id}
                        className={classWithModifiers(
                          addPublicCustomClassNamePrefix(`course-modules-list__lecture`),
                          {
                            ['available']: lecture.available,
                            ['completed']: lecture.completed,
                            ['selected']: isLectureCurrent,
                          },
                        )}
                      >
                        <LinkWithoutPrefetch
                          className={twMerge(
                            `word-break-word break-words text-left text-base`,
                            lecture.available
                              ? isLectureCurrent
                                ? `text-THEME-secondary hover:text-THEME-hover-secondary [&:hover>svg>path]:stroke-THEME-hover-secondary`
                                : twMerge(
                                    `text-THEME-primary hover:text-THEME-hover-primary [&:hover>svg>path]:stroke-THEME-hover-primary`,
                                    !lecture.completed && '[&:hover>svg>path]:stroke-[1.5px]',
                                  )
                              : 'text-THEME-disabled',
                            !lecture.completed && 'font-semibold',
                            `mt-3 flex items-center gap-2`,
                          )}
                          href={getLectureUrl(lecture.id)}
                          disabled={!lecture.available}
                          scroll={false}
                        >
                          {lecture.completed ? (
                            <RoundedCheckIcon
                              className={twMerge(
                                `ml-[3px] shrink-0 fill-THEME-block-background stroke-[1px]`,
                                isLectureCurrent
                                  ? 'stroke-THEME-secondary'
                                  : 'stroke-THEME-primary',
                              )}
                              checkmarkClassName={
                                isLectureCurrent ? 'stroke-THEME-secondary' : 'stroke-THEME-primary'
                              }
                            />
                          ) : (
                            <CircleIcon
                              className={twMerge(
                                `ml-[3px] shrink-0`,
                                lecture.available
                                  ? isLectureCurrent
                                    ? `[&>path]:stroke-THEME-secondary`
                                    : `[&>path]:stroke-THEME-primary`
                                  : twMerge(
                                      lecture.completed
                                        ? '[&>path]:stroke-[1px]'
                                        : '[&>path]:stroke-[1.5px]',
                                      `[&>path]:stroke-THEME-disabled`,
                                    ),
                              )}
                            />
                          )}
                          {lecture.name}
                          {isModuleAvailable && !lecture.available && (
                            <CourseAvailabilityIcon
                              isPostponed={drippingAccess}
                              lectureId={lecture.id}
                              redirect={canPurchase}
                            />
                          )}
                        </LinkWithoutPrefetch>
                      </li>
                    )
                  })}
                  {module.quiz && shouldShowQuiz && (
                    <li
                      ref={isQuizCurrent ? selectedStepRef : undefined}
                      className={classWithModifiers(`course-modules-list__quiz`, {
                        ['available']: module.quiz.available,
                        ['completed']: module.quiz.completed,
                        ['selected']: isQuizCurrent,
                      })}
                    >
                      <LinkWithoutPrefetch
                        disabled={!module.quiz.available}
                        className={twMerge(
                          `text-base disabled:text-THEME-disabled`,
                          module.quiz.available &&
                            (isQuizCurrent
                              ? `text-THEME-secondary hover:text-THEME-hover-secondary [&:hover>svg>path]:fill-THEME-hover-secondary`
                              : `text-THEME-primary hover:text-THEME-hover-primary [&:hover>svg>path]:fill-THEME-hover-primary`),
                          !module.quiz.completed && `font-semibold`,
                          module.quiz.available && !isQuizCurrent && 'cursor-pointer',
                          `mt-4 flex items-center gap-1`,
                        )}
                        href={getQuizUrl(module.quiz.id)}
                        onClick={() => {
                          if (module.quiz) {
                            skipScroll.current = true
                          }
                        }}
                        scroll={false}
                      >
                        <QuizIcon
                          className={twMerge(
                            `shrink-0`,
                            module.quiz.available
                              ? isQuizCurrent
                                ? '[&>path]:fill-THEME-secondary'
                                : '[&>path]:fill-THEME-primary'
                              : '[&>path]:fill-THEME-disabled',
                          )}
                        />
                        {t('course.quiz.label')}
                      </LinkWithoutPrefetch>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )
        })}
        {isCertificateAvailable && (
          <LinkWithoutPrefetch
            className={twMerge(
              'word-break-word break-words text-left text-base',
              isOnCertificatePage
                ? 'text-THEME-secondary hover:text-THEME-hover-secondary [&:hover>svg>path]:stroke-THEME-hover-secondary'
                : 'text-THEME-primary hover:text-THEME-hover-primary [&:hover>svg>path]:stroke-THEME-hover-primary',
              'mt-3 flex items-center gap-2',
            )}
            href={certificatePageUrl}
          >
            <CertificateIcon />
            {t('course.certificate.title')}
          </LinkWithoutPrefetch>
        )}
      </ul>
    )
  },
)
