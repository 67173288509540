import { ThemeInterface } from 'themes/types'
import { DataFile } from 'shared/types/datafile-type'

export type CourseId = number

export enum CourseAccessTypeEnum {
  NoAccess = 'no_access',
  FullAccess = 'full_access',
  PartialAccess = 'partial_access',
  DrippingContent = 'dripping_content',
}

export interface CourseDataInterface {
  accessFrom: string | null
  themeId: number
  colorSet: ThemeInterface | null
  displayModuleTitles: boolean
  fontFamily: string
  disableRightClick: boolean
  id: CourseId
  instructorName: string | null
  instructorPhoto: DataFile | null
  logo: DataFile | null
  name: string
  owner: { id: number }
  progress: number
  salesPage: string | null
  embeddedCode: string | null
  lectureSessionLogging: boolean
  issueCertificateUponCompletion: boolean
  completed: boolean
  dominantAccessType: CourseAccessTypeEnum
}
