import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { CourseMenuLectureInterface, CourseQuizInterface } from '../../types/course-menu-interface'
import { CourseStepEnum } from '../../types/course-step-type'
import { getCoursePageQuery } from '../../utils/get-course-page-url'
import { parseQueryId } from '../../utils/parse-query-id'
import { useGetCoursePageUrl } from '../use-course-path-url'
import useCourseSteps from '../use-course-steps'
import { StepType } from './types'
import { getContentType } from './utils'

export default function useStepRouter() {
  const [currentStep, setCurrentStep] = useState<StepType>()
  const [isEnd, setIsEnd] = useState<boolean>(false)
  const [isStart, setIsStart] = useState<boolean>(false)
  const router = useRouter()
  const coursePath = useGetCoursePageUrl()
  const { courseSteps } = useCourseSteps()

  const { lectureId: rawLectureId, quizId: rawQuizId, preview } = router.query
  const [lectureId, quizId] = [rawLectureId, rawQuizId].map(parseQueryId)

  const isCurrentStep =
    (stepType: CourseStepEnum) =>
    (step: CourseMenuLectureInterface | CourseQuizInterface | undefined | null) => {
      const isMatchingType = stepType === currentStep?.type

      if (!isMatchingType) return false

      return !!(step && step.id === currentStep?.id)
    }

  const getStepUrl = (stepType: CourseStepEnum) => (id: number) =>
    `/school/course/${coursePath}/${stepType}/${id}`

  const isPreview = preview === 'true'

  const findStep = (): StepType | undefined | null => {
    const availableSteps = courseSteps.filter(({ available }) => available)
    const uncompletedAvailableSteps = availableSteps.filter(({ completed }) => !completed)

    let stepIndex: number | null = null
    if (uncompletedAvailableSteps.length) {
      stepIndex = courseSteps.findIndex(({ id }) => id === uncompletedAvailableSteps[0].id)
    } else if (availableSteps.length) {
      stepIndex = courseSteps.findIndex(({ id }) => id === availableSteps[0].id)
    } else if (courseSteps.length) {
      stepIndex = 0
    }

    return typeof stepIndex === 'number' && courseSteps[stepIndex]
      ? {
          ...courseSteps[stepIndex],
        }
      : null
  }

  const redirectToStep = (
    step: StepType | null | undefined,
    { shallow }: { shallow: boolean } = { shallow: true },
  ) => {
    if (!step) return

    return (
      coursePath &&
      router.push(
        getCoursePageQuery({
          coursePath,
          type: step.type,
          id: step.id,
        }),
        undefined,
        { shallow },
      )
    )
  }

  const getNextStep = () => {
    if (isEnd) return

    const currentStepIndex = courseSteps.findIndex(
      step => step.type === currentStep?.type && step.id === currentStep.id,
    )
    const nextStep = courseSteps.find(
      ({ id }) => id === courseSteps.slice(currentStepIndex + 1).find(step => step.available)?.id,
    )
    return nextStep
  }

  const getPreviousStep = () => {
    if (isStart) return

    const currentStepIndex = courseSteps.findIndex(
      step => step.type === currentStep?.type && step.id === currentStep.id,
    )
    const previousStep = courseSteps.find(
      ({ id }) =>
        id ===
        courseSteps
          .slice(0, currentStepIndex)
          .reverse()
          .find(step => step.available)?.id,
    )
    return previousStep
  }

  const onNextStep = () => {
    const step = getNextStep()

    if (!step) return

    return redirectToStep(step, { shallow: false })
  }

  const onPreviousStep = () => {
    const step = getPreviousStep()

    if (!step) return

    return redirectToStep(step, { shallow: false })
  }

  const findStepById = (id?: number) =>
    id ? courseSteps?.find(step => step?.id === id) : undefined

  useEffect(() => {
    if (router.asPath) {
      const stepType = getContentType(router.asPath) as CourseStepEnum
      const id = stepType === CourseStepEnum.lecture ? lectureId : quizId
      const step = courseSteps.find(s => s.type === stepType && s.id === id)

      if (step) {
        setCurrentStep(step)
      }
    }
  }, [router.asPath, courseSteps])

  useEffect(() => {
    if (currentStep) {
      const currentStepIndex = courseSteps.findIndex(
        step => step.type === currentStep.type && step.id === currentStep.id,
      )

      const hasPrevious = courseSteps.some((step, i) => i < currentStepIndex && step.available)
      const hasNext = courseSteps.some((step, i) => i > currentStepIndex && step.available)

      setIsStart(!hasPrevious)
      setIsEnd(!hasNext)
    }
  }, [courseSteps, currentStep])

  return {
    isCurrentLecture: isCurrentStep(CourseStepEnum.lecture),
    isCurrentQuiz: isCurrentStep(CourseStepEnum.quiz),
    getLectureUrl: getStepUrl(CourseStepEnum.lecture),
    getQuizUrl: getStepUrl(CourseStepEnum.quiz),
    step: currentStep,
    findStepById,
    isPreview,
    findStep,
    redirectToStep,
    onNextStep,
    onPreviousStep,
    isEnd,
    isStart,
  }
}
